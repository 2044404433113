import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('layouts/MainLayout.vue'),
        children: [
            {
                name: 'default',
                path: '/',
                component: () => import('pages/SimpleTrade.vue'),
                props: true,
            },
            {
                name: 'pro',
                path: '/pro',
                component: () => import('pages/ProDashboard.vue'),
                props: true,
            },
            {
                name: 'tech-info',
                path: 'tech-info',
                component: () => import('pages/TechInfo.vue'),
                props: true,
            },
            {
                name: 'trade',
                path: 'trade/:id',
                component: () => import('pages/OrderInfo.vue'),
                props: true,
            },
            {
                name: 'balances',
                path: '/balances',
                component: () => import('pages/MyBalance.vue'),
                props: true,
            },
            {
                name: 'trades',
                path: '/trades',
                component: () => import('pages/MyTrades.vue'),
                props: true,
            },
            {
                name: 'terms-of-use',
                path: '/terms-of-use',
                component: () => import('pages/TermsAndConditions.vue'),
                props: true,
            },
            {
                name: 'competitions',
                path: '/competitions',
                component: () => import('pages/Competitions.vue'),
                props: true,
            },

            // TODO: Return  when ready
            // {
            //     name: 'home',
            //     path: '/home',
            //     component: () => import('pages/Home.vue'),
            //     props: true,
            // },
            // {
            //     name: 'select-dashboard',
            //     path: '/select-dashboard',
            //     component: () => import('pages/SelectDashboard.vue'),
            //     props: true,
            // },
        ],
        props: true,
    },
    // TODO: Return docs when ready
    // {
    //     path: '/documentation',
    //     component: () => import('layouts/DocumentationLayout.vue'),
    //     children: [
    //         {
    //             path: '',
    //             component: () =>
    //                 import(
    //                     'pages/documentation/conceptual-introduction/WhatMakesKryptonUnique.vue'
    //                 ),
    //         },
    //         {
    //             path: 'conceptual-introduction',
    //             children: [
    //                 {
    //                     path: 'what-makes-krypton-unique',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/conceptual-introduction/WhatMakesKryptonUnique.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'the-importance-of-speed',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/conceptual-introduction/TheImportanceOfSpeed.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'benefits',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/conceptual-introduction/Benefits.vue'
    //                         ),
    //                 },
    //             ],
    //         },
    //         {
    //             path: 'krypton-user-guides',
    //             children: [
    //                 {
    //                     path: 'intro-key-trade-variables',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/krypton-user-guides/IntroKeyTradeVariables.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'connecting-your-wallet',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/krypton-user-guides/ConnectingYourWallet.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'simple-dashboard',
    //                     children: [
    //                         {
    //                             path: 'placing-a-trade',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/simple-dashboard/PlacingATrade.vue'
    //                                 ),
    //                         },
    //                         {
    //                             path: 'advanced-mode',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/simple-dashboard/AdvancedMode.vue'
    //                                 ),
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     path: 'pro-dashboard',
    //                     children: [
    //                         {
    //                             path: 'placing-a-trade',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/pro-dashboard/PlacingATrade.vue'
    //                                 ),
    //                         },
    //                         {
    //                             path: 'predictive-charts',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/pro-dashboard/PredictiveCharts.vue'
    //                                 ),
    //                         },
    //                         {
    //                             path: 'widgets',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/pro-dashboard/Widgets.vue'
    //                                 ),
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     path: 'order-history',
    //                     children: [
    //                         {
    //                             path: 'simple-dashboard',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/order-history/SimpleDashboard.vue'
    //                                 ),
    //                         },
    //                         {
    //                             path: 'pro-dashboard',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/krypton-user-guides/order-history/ProDashboard.vue'
    //                                 ),
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     path: 'trade-tracker',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/krypton-user-guides/TradeTracker.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'my-balance',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/krypton-user-guides/MyBalance.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'api-integration',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/krypton-user-guides/APIIntegration.vue'
    //                         ),
    //                 },
    //             ],
    //         },
    //         {
    //             path: 'technical-docs-architecture',
    //             children: [
    //                 {
    //                     path: 'exchange',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/technical-docs-architecture/Exchange.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'krypton-compute',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/technical-docs-architecture/KryptonCompute.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'don',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/technical-docs-architecture/DON.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'algorithmic-support-systems',
    //                     children: [
    //                         {
    //                             path: 'market-making',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/technical-docs-architecture/algorithmic-support-systems/MarketMaking.vue'
    //                                 ),
    //                         },
    //                         {
    //                             path: 'trade-execution',
    //                             component: () =>
    //                                 import(
    //                                     'pages/documentation/technical-docs-architecture/algorithmic-support-systems/TradeExecution.vue'
    //                                 ),
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     path: 'patents',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/technical-docs-architecture/Patents.vue'
    //                         ),
    //                 },
    //             ],
    //         },
    //         {
    //             path: 'economics-research',
    //             children: [
    //                 {
    //                     path: 'trade-execution-on-krypton',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/economics-research/TradeExecutionOnKrypton.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'market-making-on-krypton',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/economics-research/MarketMakingOnKrypton.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'user-research-and-results',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/economics-research/UserResearchAndResults.vue'
    //                         ),
    //                 },
    //                 {
    //                     path: 'research-papers',
    //                     component: () =>
    //                         import(
    //                             'pages/documentation/economics-research/ResearchPapers.vue'
    //                         ),
    //                 },
    //             ],
    //         },
    //         {
    //             path: 'roadmap',
    //             component: () => import('pages/documentation/Roadmap.vue'),
    //         },
    //         {
    //             path: 'support-community',
    //             component: () =>
    //                 import('pages/documentation/SupportCommunity.vue'),
    //         },
    //     ],
    // },
    {
        path: '/:catchAll(.*)*',
        component: () => import('pages/error/PageNotFound.vue'),
    },
];

export default routes;
